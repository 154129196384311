import * as React from 'react'
import './CurrentPartner.css'
import { Component } from 'react';
import CurrentPartnerStore from '../Store/CurrentPartnerStore';
import { inject, observer } from 'mobx-react';
import TitledSpinner from '../../Common/Components/TitledSpinner/TitledSpinner';
import ProviderStore from '../../Providers/Stores/ProviderStore';
import LocalStorage from '../../Common/Services/LocalStorage';
import { RouterStore } from 'mobx-react-router';
import { Button, OverlayTrigger, Tooltip, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import SortingComponent from '../../Common/Sorting/Sorting';
import { SortingState } from '../../Common/Sorting/SortState';
import UploadComponent from '../../Upload/Components/UploadComponent';
import ProviderHttpHelper from '../../Providers/HttpHelpers/ProviderHttpHelper';
import { FaThinkPeaks } from 'react-icons/fa';
import ProviderModel from '../../Providers/Models/ProviderModel';
import ProviderStatus from '../../Providers/Models/ProviderStatus';
import faq from "./faq-icon-orange.png";

interface CurrentPartnerProps {
    currentPartner: CurrentPartnerStore,
    provider: ProviderStore,
    routing: RouterStore
}

@inject('currentPartner', 'provider', 'routing')
@observer
export default class UploadCourses extends Component<CurrentPartnerProps> {
    componentDidMount() {
        this.props.currentPartner.setUpdateOnlyMetadata(false);
    }

    async backToPartner() {
        let count = await this.props.provider.getActiveCourseCount(this.props.currentPartner.selectedPartner.providerId);
        this.props.currentPartner.selectedPartner.activeCourseCount = count.numberOfCourses;
        this.props.currentPartner.setJournal(count.journal);
        this.props.routing!.replace('/partner');
    }

    HandleChangePartnerName = (newState: SortingState) => {
    }

    async downLoadTroubleFile() {
        let response = await ProviderHttpHelper.GetTroubleFile();
        await this.download('Troubleshoot Your Errors.pdf', response);
    }

    async downLoadZipFile() {
        let response = await ProviderHttpHelper.GetZipFile();
        await this.download('Sample File.zip', response);
    }

    async downLoadMetadataFile() {
        let response = await ProviderHttpHelper.GetMetadataFile();
        await this.download('Metadata Template - Sample.xlsm', response);
    }

    async downLoadFaqFile() {
        let response = await ProviderHttpHelper.GetFaqFile();
        await this.download("CUT Frequently Asked Questions.pdf", response);
      }

    async download(name: string, response: Response){
        const url = window.URL.createObjectURL(new Blob([await response.blob()]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }

    getOnProcessingClickHandler = (providerStatus: ProviderStatus) => {
        switch (providerStatus) {
            case ProviderStatus.Processing: return this.redirectToProcessing;
            case ProviderStatus.Unsynced: return this.redirectToReport;
        }
    }

    redirectToReport = (provider: ProviderModel): void => {
        this.props.provider!.CurrentProvider = provider;
        this.props.routing!.push(`/report`);
    }
    
    redirectToProcessing = (provider: ProviderModel): void => {
        this.props.provider!.CurrentProvider = provider;
        this.props.routing!.push(`/progress`);
    }

    toggleUpdateOnlyMetadata(){
        this.props.currentPartner.setUpdateOnlyMetadata(!this.props.currentPartner.updateOnlyMetadata);
    }

    render() {
        return (
            <React.Fragment>
                <div className="faq-block">
                    <a className="faq-link" onClick={this.downLoadFaqFile.bind(this)}>
                        <img src={faq} alt="Frequently Asked Questions" />
                    </a>
                </div>
                <div className="link-back-block">
                    <span className="new-user-link" onClick={this.backToPartner.bind(this)}>Back to Manage Content</span>
                </div>
                <div className="partner-name-block">
                    <span className="partner-upload-name"><b>{this.props.currentPartner.selectedPartner.providerName}</b></span>
                </div>
                <div className="main-upload-block">
                    <span className="main-upload-label"><b>Add New Courses / Update Existing Courses</b></span>
                    <Button variant="secondary" hidden={this.props.currentPartner.getStatus == ProviderStatus.Free}
                    onClick={() => { this.getOnProcessingClickHandler(this.props.currentPartner.selectedPartner.providerStatus)(this.props.currentPartner.selectedPartner) }}>View Unsynced Courses</Button>
                    <span className="instruction-label"><b>Follow these instruction to upload new courses</b></span>
                    <span className="number-instr">1.</span>
                    <span className='instr-desc'>In order to add new courses to Cornerstone’s Platform, you will need to upload one zip file that contains the following within it:</span>
                    <span className="one-line">&#9900; Course Files</span>
                    <span className="one-line">&#9900; Thumbnail for each course</span>
                    <span className="one-line">&#9900; Course Metadata</span>
                    <span className="number-instr">2.</span>
                    <span className='instr-desc'>Course File Requirements: </span>
                    <span className="one-line">&#9900; Each course should be an individual zip file</span>
                    <span className="one-line">&#9900; Course files must be  AICC, SCORM 1.2 or xAPI. </span>
                    <span className="one-line">&#9900; Do not use these characters for course file name: less than, greater than, colon, double quote, forward slash, backslash, vertical bar, questions mark, asterisk</span>
                    <span className="number-instr">3.</span>
                    <span className='instr-desc'>Thumbnail File Requirements:</span>
                    <span className="one-line">&#9900; Format: bmp, jpg, jpeg, and png</span>
                    <span className="one-line">&#9900; Aspect Ratio: 2:1</span>
                    <span className="one-line">&#9900; File Size: Not to exceed 120KB</span>
                    <span className="one-line">&#9900; Please refer to following link to update thumbnail ratio and size: <a href="https://redketchup.io/image-resizer/" target="_blank">redketchup.io/image-resizer</a></span>
                    <span className="number-instr">4.</span>
                    <span className='instr-desc'>Course Metadata Requirements: </span>
                    <span className="one-line">&#9900; Provide course information (title, duration, description, keywords, language, etc.) in our Metadata template. You will be able to download the template here: <span className="new-user-link" onClick={this.downLoadMetadataFile.bind(this)} >Metadata Template</span> </span>
                    <span className="one-line">&#9900; Refer to the ‘Helpful Hints’ tab for requirements on the different metadata fields.  </span>
                    <span className="one-line">&#9900; Add course information in the ‘Metadata Template’ tab under the appropriate column. </span>
                    <span className="one-line">&#9900; Once course information has been added, close and save the file as an Excel Workbook (xlsx.)</span>
                    <span className="number-instr">5.</span>
                    <span className='instr-desc'>Compiling your final zip file for upload:</span>
                    <span className="one-line">&#9900; Create a blank zip file.</span>
                    <span className="one-line">&#9900; Add Thumbnails, course files, and the excel metadata workbook into the newly created zip file.
                    <span className="one-line more-mrg">&#10141; If the Thumbnail File Name has the name of the file (for a given course) in the Excel Workbook, then the Thumbnail must be present in the final zip file.</span>
                        <span className="one-line more-mrg">&#10141; If the File Name column has the name of the file (for a given course) in the Excel Workbook, then the course file must be present in the final zip file.</span>
                    </span>
                    <span className="one-line">&#9900; Ensure the zip file is not larger than 1.5GB (due to browser limitations). If your zip file is larger than 1.5GB, you will need to break down the file into multiple zip files and upload one file at a time
                    <span className="one-line more-mrg">&#10141; This means that you will need to separate metadata information into multiple metadata excel workbooks.</span>
                        <span className="one-line more-mrg">&#10141; You will need to separate the course files and thumbnails into multiple zip files</span>
                        <span className="one-line more-mrg">&#10141; Please ensure that the course file and thumbnails in the zip file match with the course file name and thumbnail file name in the metadata excel workbook.</span>
                    </span>
                    <span className="one-line">&#9900; Your final zip file should look this this: <span className="new-user-link" onClick={this.downLoadZipFile.bind(this)}>Sample Zip File</span></span>
                    <span className="one-line">&#9900; Once all the course files, thumbnails, and excel metadata workbook (with course information) have been added to the blank zip, drag and drop the zip file below (one at a time if you are uploading multiple zip files due to the size restriction).  </span>
                    <span className="one-line last-mrg">&#9900; Refer to the: <span className="new-user-link" onClick={this.downLoadTroubleFile.bind(this)}>Troubleshoot Your Error</span> document if you run into any issues while uploading</span>
                </div>
                <div className="error-center">
                    <label className='error-label error-center'>{this.props.currentPartner.getErrorState && 'Wrong file type'}</label>
                </div>
                {
                    this.props.currentPartner.getStatus != ProviderStatus.Free && <div className="error-center">
                        <label className='error-label error-center'>Your previously saved upload will be lost if you drag and drop new file</label>
                    </div>
                }
                <div className='metaData-chkbox-section'>
                    <input type='checkbox' name='updateOnlyMetadata' id='updateOnlyMetadata'
                     checked={this.props.currentPartner.updateOnlyMetadata}
                     onChange={this.toggleUpdateOnlyMetadata.bind(this)}/>
                    <label htmlFor='updateOnlyMetadata' className='metaData-label'>Update Only Metadata</label>
                </div>
                <div className="upload-block">
                    <UploadComponent />
                </div>
            </React.Fragment>
        );
    }
}
